<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="$emit('close')">
      <form @submit.prevent="agregar_incidencia">
        <div class="title">{{ !this.incidencia.subtipo ? 'Agregar' : 'Editar' }} incidencia</div>
        <div class="body">
          <div class="row form-group">
            <label for="incidencia_tipo" class="col-form-label col-sm-2">Tipo de incidencia</label>
            <div class="col-sm-10">
              <select v-model="nueva_incidencia.subtipo" name="incidencia_tipo" id="incidencia_tipo" class="form-control">
                <option value="na">Selecciona el tipo de incidencia</option>
                <option v-for="opcion in opciones_validas" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="incidencia_descript" class="col-form-label col-sm-2">Descrición</label>
            <div class="col-sm-10">
              <textarea v-model="nueva_incidencia.descripcion" name="incidencia_descript" id="incidencia_descript" cols="30" rows="10" class="form-control"></textarea>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">{{ !this.incidencia.subtipo ? 'Agregar' : 'Editar' }}</button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  export default {
    components: {
      Modal
    }
    ,props: {
      incidencia: {
        type: Object
        ,default() {
          return {
            subtipo: 'na'
            ,descripcion: null
          }
        }
      }
      ,incidencias: {
        type: Array
        ,default() {
          return [];
        }
      }
      ,catalogo: {
        type: Object
        ,default() {
          return {
            opciones: []
          }
        }
      }
    }
    ,data() {
      return {
        nueva_incidencia: {
          subtipo: 'na'
          ,descripcion: null
        }
      }
    }
    ,mounted() {
      if (this.incidencia.subtipo)
        this.nueva_incidencia = this.$helper.clone(this.incidencia);
    }
    ,methods: {
      agregar_incidencia() {
        if (this.nueva_incidencia.subtipo == 'na')
          return this.$helper.showMessage('Error','Debes seleccionar el tipo de incidencia','error','alert');

        if (!this.nueva_incidencia.descripcion || this.nueva_incidencia.descripcion.length == 0)
          return this.$helper.showMessage('Error','Debes indicar una descripción de la incidencia','error','alert');

        this.$emit('update', this.nueva_incidencia);
      }
    }
    ,computed: {
      opciones_validas() {
        let opciones_seleccionadas = [];

        this.incidencias.forEach(incidencia => {
          opciones_seleccionadas.push(incidencia.subtipo);
        })

        let opciones_validas = [];

        if (typeof this.incidencia.index == 'undefined') {
          this.catalogo.opciones.forEach(opcion => {
            if (opciones_seleccionadas.indexOf(opcion.valor) == -1)
              opciones_validas.push(opcion);
          });
        }else {
          opciones_validas = this.catalogo.opciones;
        }

        return opciones_validas;
      }
    }
  }
</script>

<style lang="scss" scoped>
  textarea {
    resize: none;
  }
</style>
<template>
  <div class="row">
    <div class="col-sm-8 offset-sm-2">
      <div class="row">
        <h1 class="col-sm-12">Carga de valuación</h1>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12">
          Selecciona y carga el resultado de la valuación del inmueble
        </div>
      </div>
      <div class="row form-group mt-4">
        <label for="" class="col-form-label col-sm-3">Valuación</label>
        <div class="col-sm-9">
          <div class="input-group">
            <div class="form-control" ref="seleccionar_archivo_nombre"></div>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" @click="seleccionar_archivo_clg">Seleccionar documento</button>
            </div>
          </div>
          <input class="seleccionar_archivo" type="file" name="seleccionar_archivo" id="seleccionar_archivo" ref="seleccionar_archivo" @change="archivo_seleccionado">
        </div>
      </div>

      <div class="row form-group">
        <label for="fecha_avaluo" class="col-form-label col-sm-3">Fecha de avaluo</label>
        <div class="col-sm-5"><input type="date" v-model="valuacion.fecha_avaluo" name="fecha_avaluo" id="fecha_avaluo" class="form-control" :class="(!valuacion.fecha_avaluo) ? 'is-invalid' : 'is-valid'" required></div>
      </div>

      <div class="row form-group">
        <label for="unidad_valuacion" class="col-form-label col-sm-3">Unidad de valuación que realizó el avaluo</label>
        <div class="col-sm-5">
          <select v-model="valuacion.unidad_valuacion" class="form-control" :class="(!valuacion.unidad_valuacion) ? 'is-invalid' : 'is-valid'"  id="unidad_valuacion" required>
            <option selected disabled value="">Selecciona una opción</option>
            <option v-for="unidad in catalogo_unidad_valuacion_avaluo.opciones" :key="unidad.id" :value="unidad.valor">{{ unidad.nombre }}</option>
          </select>

        </div>
      </div>

      <div class="row form-group">
        <label for="tipo_inmueble" class="col-form-label col-sm-3">Tipo de inmueble</label>
        <div class="col-sm-5"><input v-model="valuacion.tipo_inmueble" type="text" name="tipo_inmueble" id="tipo_inmueble" class="form-control" :class="(!valuacion.tipo_inmueble) ? 'is-invalid' : 'is-valid'" required min="0"></div>
      </div>

      <div class="row form-group">
        <label for="m2_terreno" class="col-form-label col-sm-3">M2 Terreno</label>
        <div class="col-sm-5"><input v-model="valuacion.m2_terreno" type="number" name="m2_terreno" id="m2_terreno" class="form-control" :class="(!valuacion.m2_terreno) ? 'is-invalid' : 'is-valid'"  required min="0"></div>
      </div>

      <div class="row form-group">
        <label for="m2_construccion" class="col-form-label col-sm-3">M2 Construcción</label>
        <div class="col-sm-5"><input v-model="valuacion.m2_construccion" type="number" name="m2_construccion" id="m2_construccion" class="form-control" :class="(!valuacion.m2_construccion) ? 'is-invalid' : 'is-valid'"  required min="0"></div>
      </div>

      <div class="row form-group">
        <label for="cercania_cuerpo_agua" class="col-form-label col-sm-3">Cercanía cuerpo de agua (500m)</label>
        <div class="col-sm-5">
          <div class="col-sm-9">
            <BpbRadio v-model="valuacion.cercania_cuerpo_agua" :other="{display: 'inline'}" value="1" name="cercania_cuerpo_agua" label="Sí" />
            <BpbRadio v-model="valuacion.cercania_cuerpo_agua" :other="{display: 'inline'}"  value="0" name="cercania_cuerpo_agua" label="No" />
          </div>
        </div>
      </div>

      <div class="row form-group">
        <label for="" class="col-form-label col-sm-3">Construcción Maciza (muros y techos de concreto)</label>
        <div class="col-sm-9">
          <BpbRadio v-model="valuacion.es_construccion_maciza" :other="{display: 'inline'}" value="1" name="es_construccion_maciza" label="Sí" />
          <BpbRadio v-model="valuacion.es_construccion_maciza" :other="{display: 'inline'}"  value="0" name="es_construccion_maciza" label="No" />
        </div>
      </div>

      <div class="row mt-4">
        <label for="" class="col-form-label col-sm-3">¿Se encontraron incidencias?</label>
        <div class="col-sm-9">
          <BpbRadio v-model="existe_incidencias" :other="{display: 'inline'}" value="1" name="incidencias" label="Sí" />
          <BpbRadio v-model="existe_incidencias" :other="{display: 'inline'}"  value="0" name="incidencias" label="No" />
        </div>
      </div>

      <template v-if="existe_incidencias == 1">
      <div class="row mt-4">
        <div class="col-sm-12 text-right"><button class="btn btn-secondary" @click="modal_nueva_incidencia=true">Agregar incidencia</button></div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-12">
          <table class="lista_incidencias">
            <thead>
              <tr>
                <th>Tipo de incidencia</th>
                <th>Descripción</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(incidencia, index) in incidencias_valuacion" :key="index">
                <td>{{ buscar_nombre_catalogo(incidencia.subtipo) }}</td>
                <td>{{ incidencia.descripcion }}</td>
                <td>
                  <img @click="editar_incidencia(incidencia, index)" class="mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar incidencia" title="Editar incidencia" />
                  <img @click="eliminar_incidencias(incidencia)" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar incidencia" title="Elimitar incidencia" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </template>

      <div class="row mt-4">
        <div class="col-sm-12 text-right">
          <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
          <button class="btn btn-info mr-2" @click="guardar_cambios">Guardar cambios</button>
          <button class="btn btn-success" @click="cargar_valuacion">Cargar Valuación</button>
        </div>
      </div>
    </div>

    <Nuevo v-if="modal_nueva_incidencia" :incidencia="tmp_incidencia" :catalogo="catalogo" :incidencias="incidencias" @update="agregar_incidencia" @close="cancelar_incidencia" />
  </div>
</template>

<script type="text/javascript">
  import BpbRadio from '@/components/Form/Radio'
  import Nuevo from './Valuacion/Nuevo'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'
  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'
  import apiValija from '@/apps/formalizacion/api/v2/valija'

  export default {
    components: {
      BpbRadio, Nuevo
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            solicitud: null
            ,incidencias: []
          }
        }
      }
    }
    ,data() {
      return {
        existe_incidencias: 0
        ,incidencia: 1
        ,incidencias: []
        ,incidencias_eliminar: []
        ,modal_nueva_incidencia: false
        ,tmp_incidencia: {
          subtipo: null
          ,descripcion: null
        }
        ,catalogo: {
          opciones: []
        }
        ,catalogo_unidad_valuacion_avaluo: {
          nombre: '',
          opciones: []
        }
        ,valuacion: {
          fecha_avaluo: null,
          unidad_valuacion: null,
          tipo_inmueble: null,
          m2_terreno: null,
          m2_construccion: null,
          cercania_cuerpo_agua: 0,
          es_construccion_maciza: 1,
        }
      }
    }
    ,mounted() {
      this.cargarIncidenciasSolicitud()

      this.valuacion.fecha_avaluo = this.solicitud.valuador_fecha_avaluo;
      this.valuacion.unidad_valuacion = this.solicitud.valuador_unidad_valuacion;
      this.valuacion.tipo_inmueble = this.solicitud.valuador_tipo_inmueble;
      this.valuacion.m2_terreno = this.solicitud.valuador_m2_terreno;
      this.valuacion.m2_construccion = this.solicitud.valuador_m2_construccion;

      if(this.solicitud.valuador_es_construccion_maciza) {
        this.valuacion.es_construccion_maciza = this.solicitud.valuador_es_construccion_maciza;
      }

      if(this.solicitud.valuador_cercania_cuerpo_agua) {
        this.valuacion.cercania_cuerpo_agua = this.solicitud.valuador_cercania_cuerpo_agua;
      }

      this.obtener_catalogo_unidad_valuacion()
    }
    ,methods: {
      seleccionar_archivo_clg() {
        this.$refs.seleccionar_archivo.click();
      }
      ,archivo_seleccionado() {
        if (this.$refs.seleccionar_archivo.files.length > 0)
          this.$refs.seleccionar_archivo_nombre.innerHTML = this.$refs.seleccionar_archivo.files[0].name;
        else
          this.$refs.seleccionar_archivo_nombre.innerHTML = '';
      }
      ,async cargar_valuacion() {
        try {

          if (!this.validarCampos(true)){
            return;
          }

          console.log('incidencias_valuacion', this.incidencias.length)
          if (this.incidencias.length > 0 || this.incidencias_eliminar.length > 0) {
            this.guardar_cambios();
          }

          let formdata = new FormData();
          formdata.append('solicitud_id', this.solicitud.solicitud);
          formdata.append('usuario', this.$auth.getUser().nombre);
          formdata.append('archivos[][archivo]', this.$refs.seleccionar_archivo.files[0]);
          formdata.append('archivos[][tipo]', 'valuacion_inmueble');

          let res;
          res = (await apiValija.cargar_documentos(formdata)).data;
          this.$log.info('res',res);

          this.$emit('avanzar');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async guardar_cambios() {
        try {
          if(!this.validarCampos(false)){
            return;
          }

          let payload = {
            incidencias_tipo: 'avaluo'
            ,incidencias: this.incidencias
            ,incidencias_eliminar: this.incidencias_eliminar
          }

          let res = (await apiSolicitud.incidencias_sincronizar(this.solicitud.id, payload)).data;
          this.$log.info('res', res);
          let response_editar_solicitud = (await apiSolicitud.editar_solicitud(this.solicitud.id, {cargar_valuacion: true,...this.valuacion})).data;
          this.$log.info('res', response_editar_solicitud);

          this.incidencias_eliminar = [];

          this.$emit('actualizar');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,agregar_incidencia(incidencia) {
        this.$log.info('incidencia: ', incidencia);
        incidencia.tipo = 'avaluo';

        if (typeof incidencia.index == 'undefined') {
          incidencia.index = this.incidencias.length;
          this.incidencias.push(incidencia);
        }
        else {
          let incidencias = this.$helper.clone(this.incidencias);
          this.incidencias = [];

          for(let i=0; i<incidencias.length; i++) {
            if (i == incidencia.index)
              incidencias[i] = incidencia;
          }

          this.incidencias = incidencias;
        }

        this.cancelar_incidencia();
      }
      ,cancelar_incidencia() {
        this.tmp_incidencia = {
          subtipo: null
          ,descripcion: null
        }
        this.modal_nueva_incidencia = false;
      }
      ,buscar_nombre_catalogo(valor) {
        let nombre;

        this.catalogo.opciones.forEach(opcion => {
          if (opcion.valor == valor)
            nombre = opcion.nombre;
        })

        return nombre;
      }
      ,editar_incidencia(incidencia, index) {
        incidencia.index = index;
        this.tmp_incidencia = incidencia;
        this.modal_nueva_incidencia = true
      }
      ,eliminar_incidencias(incidencia) {
        let incidencias = [];

        this.incidencias.forEach(tmp_incidencia => {
          if (tmp_incidencia.index != incidencia.index)
            incidencias.push(tmp_incidencia);
          else
            this.incidencias_eliminar.push(tmp_incidencia);
        })

        this.incidencias = incidencias;
      }
      ,async obtener_catalogo_unidad_valuacion() {
        if (!this.catalogo_unidad_valuacion_avaluo.opciones.length) {
          try {
            this.catalogo_unidad_valuacion_avaluo = (await apiCatalogo.buscar_catalogo('unidad_valuacion_avaluo')).data;
            this.$store.commit('set',{formalizacion: {unidad_valuacion_avaluo: this.catalogo_unidad_valuacion_avaluo }});
          }catch(e) {
            this.$log.info('error',e);
            this.$helper.showAxiosError(e,'Error');
          }
        }
      }
      ,validarCampos(cargar_archivo_valuacion){
        if (cargar_archivo_valuacion){
          if (this.$refs.seleccionar_archivo.files.length == 0) {
            this.$helper.showMessage('Error', 'Debes seleccionar un archivo', 'error', 'alert');
            return false
          }

          if (this.existe_incidencias == 1 && this.incidencias.length == 0) {
            this.$helper.showMessage('Error', 'Si existen incidencias debes al menos indicar una', 'error', 'alert');
            return false
          }
        }
        console.log('valuacion', Object.values(this.valuacion))
        if ( Object.values(this.valuacion).some((valor_campo) => !valor_campo)){
          this.$helper.showMessage('Carga de valuación','Es necesario llenar todos los campos faltantes','error','alert');
          return false;
        }
        return true;
      }
      ,cargarIncidenciasSolicitud(){
        let incidencias = this.incidencias_valuacion;
        if (incidencias.length > 0) {
          this.existe_incidencias = 1;
          this.incidencias = incidencias;
        }
      }
    }
    ,computed: {
      incidencias_valuacion() {
        let incidencias = [];

        let index = 0;
        this.incidencias.forEach(incidencia => {
          if (incidencia.tipo == 'avaluo') {
            incidencia.index = index;
            incidencias.push(incidencia);
            index++;
          }
        })

        return incidencias;
      }
    }
    ,watch: {
      async existe_incidencias() {
        if (this.existe_incidencias == 0)
          this.incidencias = []

        if (this.catalogo.opciones.length == 0) {
          if (!this.$store.state.formalizacion || !this.$store.state.formalizacion.incidencias_valuacion) {
            try {
              this.catalogo = (await apiCatalogo.buscar_catalogo('incidencias_valuacion')).data;

              this.$store.commit('set',{formalizacion: {incidencias_valuacion: this.catalogo}});
              this.$log.info('catalogo', this.catalogo);
            }catch(e) {
              this.$log.info('error',e);
              this.$helper.showAxiosError(e,'Error');
            }
          }else {
            this.catalogo = this.$store.state.formalizacion.incidencias_valuacion;
          }
        }
      }
      ,solicitud: {
        handler() {
          // if (this.solicitud.incidencias && this.solicitud.incidencias.length > 0) {
            this.$log.info('incidencias', this.solicitud.incidencias.length)
            this.incidencias = this.solicitud.incidencias;
            
            if (this.incidencias_valuacion.length > 0)
              this.existe_incidencias = 1;
          // }
        }
        ,deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .lista_incidencias {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #4C4C4C;
          color: #fff;
          padding: 5px 10px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: solid 1px #4C4C4C;
        td {
          padding: 5px 10px;

          img {
            width: 20px;
          }
        }

        td:nth-child(3) {
          text-align: center;
        }
      }

      tr:last-child {
        border-bottom: 0px;
      }
    }
  }
</style>
<template>
  <div>
    <h1 class="col-sm-12 text-right mt-4 mb-4">Solicitud {{ solicitud.solicitud }}</h1>
    <button class="btn btn-info mr-2" @click="modal_informacion_adicional=true">Información de contacto</button>

    <div class="row mb-2">
      <div class="col-sm-12 text-right">
        <button v-if="etapa != 'inicio'" class="btn btn-outline-secondary mr-2" @click="modal_calendario=true">Calendario</button>
        <button class="btn btn-secondary mr-2" @click="modal_observaciones=true">Observaciones</button>
        <button class="btn btn-info mr-2" @click="modal_documentos=true">Documentación</button>
        <button v-if="incidencias_abiertas.length > 0" class="btn btn-warning mr-2" @click="modal_incidencias=true">Incidencias</button>
        <button class="btn btn-danger" @click="modal_no_viable=true">Marcar como inválida</button>
      </div>
    </div>

    <Step css_class="mb-4" :pasos="pasos" :activo="etapa" title="ETAPAS" />

    <div class="mb-4">
      <div v-if="etapa == 'inicio'" class="row">
        <Cita :solicitud="solicitud" :etapa="etapa" @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" />
      </div>

      <div v-if="etapa == 'cita_valuacion'">
        <Valuacion :solicitud="solicitud" @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" @actualizar="obtener_solicitud" />
      </div>

      <div v-if="etapa == 'carga_valuacion'">
        <Costo :solicitud="solicitud" @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" />
      </div>

      <div v-if="etapa == 'costos_valuacion'">
        <Factura :solicitud="solicitud" @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" />
      </div>

      <div v-if="etapa == 'pago_factura'">
        <Finalizacion @retroceder="retroceder_etapa=true" />
      </div>
    </div>

    <Retroceder v-if="retroceder_etapa" :tipo_usuario="1" :solicitud="solicitud" @cancelar="retroceder_etapa=false" @actualizar="cerrar_retroceder_actualizar" />
    <ListaIncidencia v-if="modal_incidencias" :solicitud="solicitud" :tipo_inicidencia="['avaluo']" @close="modal_incidencias=false" @update="obtener_solicitud" />
    <Observacion v-if="modal_observaciones" :solicitud="solicitud" @update="obtener_solicitud" :observacion_tipo_usuario="true" @close="modal_observaciones=false" />
    <Documento v-if="modal_documentos" :solicitud="solicitud" @update="obtener_solicitud" @close="modal_documentos=false" />
    <NoViable v-if="modal_no_viable" @cancelar="modal_no_viable=false" :solicitud="solicitud"/>
    <Calendario v-if="modal_calendario" :solicitud="solicitud" @close="modal_calendario=false" />
    <InformacionAdicionalSolicitud v-if="modal_informacion_adicional" :solicitud="solicitud" @close="modal_informacion_adicional=false" />
  </div>
</template>

<script type="text/javascript">
  import Step from '@/components/Step'
  import Calendar from '@/components/Calendar'

  // Etapas en componentes
  import Cita from './Valuador/Cita'
  import Valuacion from './Valuador/Valuacion'
  import Incidencia from './Valuador/Incidencia'
  import Costo from './Valuador/Costo'
  import Factura from './Valuador/Factura'
  import Finalizacion from './Valuador/Finalizacion'

  import Retroceder from '@/apps/formalizacion/Componentes/Retroceder'
  import ListaIncidencia from '@/apps/formalizacion/Componentes/ListaIncidencia'
  import Observacion from '@/apps/formalizacion/Componentes/Observacion'
  import Documento from '@/apps/formalizacion/Componentes/Documento'
  import NoViable from '@/apps/formalizacion/Componentes/NoViable'
  import Calendario from '@/apps/formalizacion/Componentes/Calendario'

  import apiUsuarios from '@/apps/formalizacion/api/v2/usuarios'
  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

  import InformacionAdicionalSolicitud from '@/apps/formalizacion/Componentes/InformacionAdicionalSolicitud'

  export default {
    components: {
      Step, Retroceder, Calendar, Cita, Valuacion, Incidencia, Costo, Finalizacion, ListaIncidencia, Observacion, Documento, Factura, NoViable, Calendario, InformacionAdicionalSolicitud
    }
    ,data() {
      return {
        pasos: [
          {
            texto: "Cita valuación"
            ,valor: "inicio"
          }
          ,{
            texto: "Carga de valuación"
            ,valor: "cita_valuacion"
          }
          // ,{
          //   texto: "Incidencias"
          //   ,valor: "carga_valuacion"
          // }
          ,{
            texto: 'Costos'
            ,valor: 'carga_valuacion'
          }
          ,{
            texto: 'Pago factura'
            ,valor: 'costos_valuacion'
          }
          ,{
            texto: 'Finalización'
            ,valor: 'pago_factura'
          }
        ]
        ,solicitud: {
          solicitud: null
          ,sla: []
        }
        ,etapa: 'inicio'
        ,retroceder_etapa: false
        ,modal_incidencias: false
        ,modal_observaciones: false
        ,modal_documentos: false
        ,modal_no_viable: false
        ,modal_calendario: false
        ,modal_informacion_adicional:false
      }
    }
    ,mounted() {
      this.obtener_solicitud();
    }
    ,methods: {
      async obtener_solicitud() {
        try {
          this.solicitud = (await apiUsuarios.obtener_solicitud(this.$route.params.solicitud)).data;
          this.solicitud.tmp_up = this.$moment();
          this.$log.info('solicitud_micro: '+this.solicitud.tmp_up);

          this.$log.info('slas: '+this.solicitud.sla.length);
          this.$log.info('validas: ',this.etapas_validas);
          if (this.solicitud.sla.length > 0) {
            
            if (this.etapas_validas.length > 0)
              this.etapa = this.incidencias_abiertas[this.incidencias_abiertas.length-1].tipo;
            else
              this.etapa = 'inicio';
          }else
            this.etapa = 'inicio';
        }catch(e) {
          this.$log.info('error',e)
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async avanzar_etapa() {
        try {
          let res = (await apiSolicitudes.avanzar_etapa(this.solicitud.id)).data;
          this.$log.info('res',res);

          this.obtener_solicitud();
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async cerrar_retroceder_actualizar() {
        this.obtener_solicitud();
        this.retroceder_etapa = false;
      }
      ,obtener_ultima_etapa_activa(etapas) {
        let etapas_activas = []

        etapas.forEach(etapa => {
          if (etapa.tipo_usuario == 1 && etapa.estatus == 1)
            etapas_activas.push(etapa);
        })

        return etapas_activas;
      }
    }
    ,computed: {
      etapas_validas() {
        let etapas = [];

        this.solicitud.sla.forEach(etapa => {
          if (etapa.tipo_usuario == 1)
            etapas.push(etapa);
        })

        return etapas;
      }
      ,incidencias_abiertas() {
        let incidencias = [];

        this.etapas_validas.forEach(incidencia => {
          if (incidencia.estatus == 1)
            incidencias.push(incidencia);
        })

        return incidencias;
      }
    }
  }
</script>

<style lang="scss">
  .seleccionar_archivo {
    display: none;
  }
</style>
<template>
  <div>
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div class="row form-group">
          <h1 class="col-sm-12">Pago de factura</h1>
        </div>

        <div class="row form-group">
          <div class="col-sm-12">Indica la fecha en que se realizo el pago de la factura</div>
        </div>

        <div class="row form-group">
          <label for="fecha_pago" class="col-form-label col-sm-3">Fecha de pago</label>
          <div class="col-sm-9"><input v-model="fecha_pago" type="date" name="fecha_pago" id="fecha_pago" class="form-control" :class="(!fecha_pago) ? 'is-invalid' : 'is-valid'" required></div>
        </div>

        <div class="row form-group">
          <label for="fecha_pago_factura_bpb" class="col-form-label col-sm-3">Fecha de pago BpB</label>
          <div class="col-sm-9"><input v-model="fecha_pago_factura_bpb" type="date" name="fecha_pago_factura_bpb" id="fecha_pago_factura_bpb" class="form-control" :class="(!fecha_pago_factura_bpb) ? 'is-invalid' : 'is-valid'" required></div>
        </div>


        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
            <button class="btn btn-success" @click="guardar_cambios">Continuar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
    }
    ,data() {
      return {
        fecha_pago: null,
        fecha_pago_factura_bpb: null,
      }
    }
    ,mounted() {
      if (this.solicitud.fecha_pago_factura_at)
        this.fecha_pago = this.solicitud.fecha_pago_factura_at;

      if (this.solicitud.fecha_pago_factura_bpb)
        this.fecha_pago_factura_bpb = this.solicitud.fecha_pago_factura_bpb;
    }
    ,methods: {
      async guardar_cambios() {
        try {
          if (!this.fecha_pago || !this.fecha_pago_factura_bpb)
            return this.$helper.showMessage('Pago de factura error','Debes definir la fecha de pago y la fecha de pago BpB','error','alert');

          let res = (await apiSolicitud.editar_solicitud(this.solicitud.id, {
            fecha_pago_factura: this.fecha_pago,
            fecha_pago_factura_bpb: this.fecha_pago_factura_bpb,
          }))
          this.$log.info('res', res);

          this.$emit('avanzar');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>
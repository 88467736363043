<template>
  <div>
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div class="row form-group">
          <h1 class="col-sm-12">Costos de valuación</h1>
        </div>
        <form @submit.prevent="guardar_datos">
          
          <div class="row form-group">
            <label for="costo_avaluo" class="col-form-label col-sm-3">Monto avalúo</label>
            <div class="col-sm-9">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">$</span>
                </div>
                <input v-model="costo_avaluo" type="text" class="form-control" name="costo_avaluo" id="costo_avaluo" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1" @focus="limpiar_valor(1)" @blur="actualizar_valor(1)">
              </div>
              <small>Valor comercial obtenido del Avalúo</small>
            </div>
          </div>
          
          <div class="row form-group">
            <label for="monto_complementario" class="col-form-label col-sm-3">Monto complementario</label>
            <div class="col-sm-9">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">$</span>
                </div>
                <input v-model="monto_complementario" type="text" class="form-control" name="monto_complementario" id="monto_complementario" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1" @focus="limpiar_valor(2)" @blur="actualizar_valor(2)">
              </div>
              <small>Remanente de pago por el costo de avalúo a cubrir con el crédito</small>
            </div>
          </div>
          
          <hr>

          <div class="row form-group">
            <label for="monto_total" class="col-form-label col-sm-3">Costo total Avalúo</label>
            <div class="col-sm-9">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">$</span>
                </div>
                <input v-model="monto_total" type="text" class="form-control" name="monto_total" id="monto_total" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1" @focus="limpiar_valor(3)" @blur="actualizar_valor(3)">
              </div>
              <small>El costo total del avalúo de acuerdo al arancel</small>
            </div>
          </div>
          
          <div class="row form-group">
            <label for="monto_comision" class="col-form-label col-sm-3">Monto de comisión</label>
            <div class="col-sm-9">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">$</span>
                </div>
                <input v-model="monto_comision" type="text" class="form-control" name="monto_comision" id="monto_comision" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1" @focus="limpiar_valor(4)" @blur="actualizar_valor(4)">
              </div>
              <small>Monto de comision que la Unidad de Valuación pagará a BPB por la realización del avalúo</small>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-sm-12">
              <h4>Dirección del inmueble valuado</h4>
            </div>
          </div>

          <div class="direccion">
            <div class="row form-group">
              <label for="calle" class="col-form-label col-sm-3">Calle</label>
              <div class="col-sm-9"><input v-model="direccion.calle" type="text" name="calle" id="calle" class="form-control"></div>
            </div>
            <div class="row form-group">
              <label for="numero_exterior" class="col-form-label col-sm-3">Número exterior</label>
              <div class="col-sm-9"><input v-model="direccion.numero_exterior" type="text" name="numero_exterior" id="numero_exterior" class="form-control"></div>
            </div>
            <div class="row form-group">
              <label for="numero_interior" class="col-form-label col-sm-3">Número interior (opcional)</label>
              <div class="col-sm-9"><input v-model="direccion.numero_interior" type="text" name="numero_interior" id="numero_interior" class="form-control"></div>
            </div>
            <div class="row form-group">
              <label for="colonia" class="col-form-label col-sm-3">Colonia</label>
              <div class="col-sm-9"><input v-model="direccion.colonia" type="text" name="colonia" id="colonia" class="form-control"></div>
            </div>
            <div class="row form-group">
              <label for="delegacion" class="col-form-label col-sm-3">Delegación / Municipio</label>
              <div class="col-sm-9"><input v-model="direccion.delegacion" type="text" name="delegacion" id="delegacion" class="form-control"></div>
            </div>
            <div class="row form-group">
              <label for="ciudad" class="col-form-label col-sm-3">Ciudad (opcional)</label>
              <div class="col-sm-9"><input v-model="direccion.ciudad" type="text" name="ciudad" id="ciudad" class="form-control"></div>
            </div>
            <div class="row form-group">
              <label for="estado" class="col-form-label col-sm-3">Estado</label>
              <div class="col-sm-9"><input v-model="direccion.estado" type="text" name="estado" id="estado" class="form-control"></div>
            </div>
            <div class="row form-group">
              <label for="cp" class="col-form-label col-sm-3">CP</label>
              <div class="col-sm-9"><input v-model="direccion.cp" type="text" name="cp" id="cp" class="form-control"></div>
            </div>

            <div class="row form-group">
              <div class="col-sm-12 text-right">
                <button class="btn btn-warning mr-2" type="button" @click="$emit('retroceder')">Retroceder etapa</button>
                <button class="btn btn-success">Guardar costos / dirección</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div> 
  </div>
</template>

<script type="text/javascript">
  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            solicitud: null
          }
        }
      }
    }
    ,data() {
      return {
        costo_avaluo: null
        ,monto_complementario: null
        ,monto_total: null
        ,monto_comision: null
        ,direccion: {
          calle: null
          ,numero_exterior: null
          ,numero_interior: null
          ,colonia: null
          ,delegacion: null
          ,ciudad: null
          ,estado: null
          ,cp: null
        }
      }
    }
    ,mounted() {
      if (this.solicitud.costo_avaluo)
        this.costo_avaluo = this.solicitud.costo_avaluo

      if (this.solicitud.monto_complementario)
        this.monto_complementario = this.solicitud.monto_complementario

      if (this.solicitud.monto_total)
        this.monto_total = this.solicitud.monto_total

      if (this.solicitud.monto_comision)
        this.monto_comision = this.solicitud.monto_comision

      if (this.solicitud.inmuebles && this.solicitud.inmuebles.length > 0)
        this.direccion = this.solicitud.inmuebles[0];

      this.actualizar_valor(1);
      this.actualizar_valor(2);
      this.actualizar_valor(3);
      this.actualizar_valor(4);

    }
    ,methods: {
      async guardar_datos() {
        try {
          if (!this.costo_avaluo || this.costo_avaluo == 0)
            return this.$helper.showMessage('Error','Debes definir el costo del avalúo','error','alert');

          if (!this.monto_complementario || this.monto_complementario == 0)
            return this.$helper.showMessage('Error','Debes definir el costo del monto complementario','error','alert');

          if (!this.monto_total || this.monto_total == 0)
            return this.$helper.showMessage('Error','Debes definir el costo del monto total','error','alert');

          if (!this.monto_comision || this.monto_comision == 0)
            return this.$helper.showMessage('Error','Debes definir el costo del monto de la comisión','error','alert');

          if (!this.direccion.calle)
            return this.$helper.showMessage('Error','Debes definir la calle','error','alert');

          if (!this.direccion.numero_exterior)
            return this.$helper.showMessage('Error','Debes definir el número exterior','error','alert');

          if (!this.direccion.colonia)
            return this.$helper.showMessage('Error','Debes definir la colonia','error','alert');

          if (!this.direccion.delegacion)
            return this.$helper.showMessage('Error','Debes definir la delegación','error','alert');

          if (!this.direccion.estado)
            return this.$helper.showMessage('Error','Debes definir el estado','error','alert');

          if (!this.direccion.cp)
            return this.$helper.showMessage('Error','Debes definir el código postal','error','alert');

          this.limpiar_valor(1);
          this.limpiar_valor(2);
          this.limpiar_valor(3);
          this.limpiar_valor(4);

          let costo_avaluo = this.costo_avaluo;
          let monto_complementario = this.monto_complementario;
          let monto_total = this.monto_total;
          let monto_comision = this.monto_comision;

          let res = (await apiSolicitud.editar_solicitud(this.solicitud.id, {costo_avaluo, monto_complementario, monto_total, monto_comision, estatus_valuacion: 2})).data;
          this.$log.info('res', res);

          if (this.direccion.id)
            res = (await apiSolicitud.editar_inmueble(this.solicitud.id, this.direccion.id, this.direccion)).data;
          else
            res = (await apiSolicitud.crear_inmueble(this.solicitud.id, this.direccion)).data;
          this.$log.info('res inmueble', res);

          this.$emit('avanzar');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,limpiar_valor(tipo) {
        if (tipo == 1) {
          this.costo_avaluo = this.costo_avaluo ? this.costo_avaluo.replace(new RegExp(/[^\d\.]/g),'') : '';
          this.costo_avaluo = this.costo_avaluo == '0.00' ? 0 : this.costo_avaluo;
        }else if (tipo == 2) {
          this.monto_complementario = this.monto_complementario ? this.monto_complementario.replace(new RegExp(/[^\d\.]/g),'') : '';
          this.monto_complementario = this.monto_complementario == '0.00' ? 0 : this.monto_complementario;
        }else if (tipo == 3) {
          this.monto_total = this.monto_total ? this.monto_total.replace(new RegExp(/[^\d\.]/g),'') : '';
          this.monto_total = this.monto_total == '0.00' ? 0 : this.monto_total;
        }else if (tipo == 4) {
          this.monto_comision = this.monto_comision ? this.monto_comision.replace(new RegExp(/[^\d\.]/g),'') : '';
          this.monto_comision = this.monto_comision == '0.00' ? 0 : this.monto_comision;
        }
      }
      ,actualizar_valor(tipo) {
        if (tipo == 1) {
          this.limpiar_valor(1);
          this.costo_avaluo = this.$helper.moneyFormat(this.costo_avaluo);
        }else if (tipo == 2) {
          this.limpiar_valor(2);
          this.monto_complementario = this.$helper.moneyFormat(this.monto_complementario)
        }else if (tipo == 3) {
          this.limpiar_valor(3);
          this.monto_total = this.$helper.moneyFormat(this.monto_total)
        }else if (tipo == 4) {
          this.limpiar_valor(4);
          this.monto_comision = this.$helper.moneyFormat(this.monto_comision)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .direccion {
    .row:nth-child(2n+0) {
      label {
        background-color: #E6E6E6;
      }
    }
  }
</style>
<template>
  <div class="col-sm-12">
    <div class="col-sm-8 offset-sm-2">
      <div class="row">
        <h1 class="col-sm-12">Cita</h1>
      </div>
      <div class="row mt-4">
        <div class="col-sm-12">
          <Cita :options="{
              agregar_eventos: agregar_eventos, 
              agregar_invitados: false, 
              invitados: invitados, 
              evento
            }" :eventos="eventos" @nEvento="crear_evento" @upEvent="actualizar_evento" @caEvent="cancelar_evento" />
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-12 text-right">
          <button v-if="evento_agregado" class="btn btn-success" @click="$emit('avanzar')">Avanzar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import Cita from '@/components/Calendar'

  import apiUsuario from '@/apps/formalizacion/api/v2/usuarios'
  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'

  import appconfig from '@/apps/formalizacion/config'

  export default {
    components: {
      Cita
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
            ,solicitud: null
            ,nombre: null
            ,email: null
          }
        }
      }
      ,etapa: {
        type: String,
        default: 'na'
      }
    }
    ,data() {
      return {
        evento: {
          title: null
          ,descript: null
          ,location: null
        }
        ,invitados: []
        ,eventos: []
        ,agregar_eventos: true
        ,config: null
        ,evento_agregado: false
      }
    }
    ,mounted() {
      this.$log.info('cargamos configuracion');
      this.config = appconfig[this.$helper.getEnv()];

      this.alimentar_calendario();
    }
    ,methods: {
      async obtener_eventos() {
        try {
          this.eventos = (await apiUsuario.cuenta_obtener_eventos()).data;

          let encontrado = false;
          for(let i=0; i<this.eventos.length; i++) {
            this.eventos[i].attendees = this.eventos[i].invitados;
            this.$log.info('solcitud_id: ',this.solicitud.id);
            this.$log.info('evento_solicitud_id: ',this.eventos[i].solicitud_id);
            if (this.eventos[i].solicitud_id === this.solicitud.id) {
              this.evento = this.eventos[i];
              this.$log.info('asignar evento cita', this.evento);
              encontrado = true;
              this.evento_agregado = true;
            }
          }

          this.$log.info('se encontro evento:', encontrado);
          this.$log.info('se encontro evento data:', this.evento);

          this.agregar_eventos = !encontrado;

          this.$log.info('eventos', this.eventos);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async crear_evento(evento) {
        this.$log.info('evento',evento);

        try {
          evento.event = 'firma_valuacion';
          let res = (await apiSolicitud.generar_cita(this.solicitud.id, evento));
          this.$log.info('res', res);

          this.agregar_eventos = false;
          this.obtener_eventos();
          this.$emit('avanzar');
        }catch(e) {
          // errores.push({evento: eventos[i].title, error: e.getMessage()});
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async actualizar_evento(evento) {
        try {
          let res = (await apiSolicitud.actualizar_cita(this.solicitud.id, evento));
          this.$log.info('res',res);

          this.agregar_eventos = false;
          this.obtener_eventos();

          this.$emit('avanzar');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async cancelar_evento(idEvent) {
        try {
          let res = (await apiSolicitud.cancelar_cita(this.solicitud.id, idEvent));
          this.$log.info('res',res);

          this.agregar_eventos = false;
          this.obtener_eventos();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,alimentar_calendario() {
        this.invitados = [
          {
            name: this.$auth.getUser().nombre
            ,email: this.$auth.getUser().getContact('email').valor
          }
          ,{
            name: this.solicitud.nombre
            ,email: this.solicitud.email
          }
          ,...this.config.attendees
        ];

        this.$log.info('invitados', this.invitados);

        this.evento.title = 'Solicitud '+this.solicitud.solicitud+': Valuación de inmueble'
        this.evento.descript = 'Evento para la valuación del inmueble'
        this.evento.location = this.solicitud.calle+', '+this.solicitud.colonia+', '+this.solicitud.municipio+', '+this.solicitud.estado+' '+this.solicitud.cp

        this.obtener_eventos();
      }
    }
    ,watch: {
      solicitud: {
        handler() {
          this.$log.info('solicitud up');
          this.alimentar_calendario();
        }
        ,deep: true
      }
      ,etapa(value) {
        this.$log.info('etapa', value)
        this.alimentar_calendario();
      }
    }
  }
</script>
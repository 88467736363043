var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"col-sm-8 offset-sm-2"},[_vm._m(0),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-sm-12"},[_c('Cita',{attrs:{"options":{
            agregar_eventos: _vm.agregar_eventos, 
            agregar_invitados: false, 
            invitados: _vm.invitados, 
            evento: _vm.evento
          },"eventos":_vm.eventos},on:{"nEvento":_vm.crear_evento,"upEvent":_vm.actualizar_evento,"caEvent":_vm.cancelar_evento}})],1)]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm-12 text-right"},[(_vm.evento_agregado)?_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.$emit('avanzar')}}},[_vm._v("Avanzar")]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('h1',{staticClass:"col-sm-12"},[_vm._v("Cita")])])
}]

export { render, staticRenderFns }